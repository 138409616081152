@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  width: 100%;
  max-width: 380px;
  height: 130px;
  background-color: $brand;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  display: flex;
  align-content: center;
  margin: 10px 0;
  padding: 0 20px 0 30px;
  cursor: pointer;
}

.ContentWrapper {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.IconWrapper {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: $brand600;
  align-items: center;
  justify-content: center;
  display: flex;
  color: $neutral100;
}

.Decoy {
  display: none;
}

@media screen and #{$tablet} {
  .Wrapper {
    flex: 1;
    height: auto;
    margin: 0 10px;
    align-self: stretch;
    flex-direction: column;
    justify-content: space-around;
  }

  .ContentWrapper {
    flex-direction: column;
  }

  .Decoy {
    display: block;
    height: 60px;
  }

  .IconWrapper {
    width: 60px;
    height: 60px;
    font-size: 1.5em;
  }
}

@media screen and #{$mobile} {
  .Wrapper {
    width: 100%;
    flex: 1;
    margin: 5px 0;
    max-width: none;
    max-height: 130px;
    min-height: 130px;
  }
}