@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  background-color: $neutral200;
  width: 100%;
  height: calc(100% - 40px);
  margin-top: 40px;
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $materialTitleBackground;
  padding: 0 30px;
  height: 50px;
  color: $neutralOpacity700;
  font-weight: bold;

  p {
    margin: 0;
    padding-left: 10px;
  }
}

.Content {
  padding: 30px;
  height: calc(100% - 80px);

  iframe {
    height: 100%;
  }
}

@media screen and #{$tablet}, #{$mobile} {
  .Wrapper {
    margin-top: 0;
  }
}
