@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  margin-top: 40px;
  background-color: $materialBackground;
  width: 100%;
  height: calc(100% - 40px);
}

.Header {
  height: 50px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.WrapperHeader {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: $materialTitleBackground;
  padding: 0 20px;
}

.LogoWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 10px;
}

.Logo {
  width: 30px;
  height: 30px;
}

.Sticker {
  position: absolute;
  height: 35px;
  margin-left: -14px;
  margin-top: -0.5em;
  width: 30px;
}

.Title {
  color: $neutralOpacity700;
  display: flex;
  align-items: center;
  font-weight: bold;

  p {
    margin: 0;
  }
}

.Description {
  padding: 30px;

  p {
    text-align: justify;
    color: $neutralOpacity700;
  }

  a {
    font-weight: bold;
    border: 1px solid $brand;
    color: $brand;
    border-radius: 10px;
    padding: 15px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: $neutral300;

    div {
      background-color: $neutral400;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    span {
      width: 15px;
      height: 17px;
      display: inline-block;
      background: url('../../../../assets/images/arrow-right.svg');
    }
  }

  h3 {
    font-size: 18px;
    color: $brand;
    margin-bottom: 10px;
  }

  ul {
    margin-left: -1em;
  }

  ul li {
    list-style: none;

    div {
      display: inline;
    }
  }

  ul li:before {
    content: "";
    width: 0;
    height: 0;
    border: 5px solid $brand;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  .Info {
    text-align: right;
    font-weight: bold;
  }
}
