@import '../../../../assets/StyleVariables.module.scss';

.ItemWrapper {
  aspect-ratio: 1/1;
  height: 100%;
  border-radius: 14px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url('../../../../assets/images/defaultNewsBackground.jpg');
}

.ItemWrapper:focus {
  outline: none;
}

.ItemWrapper p {
  margin: 0;
}

.ItemTitle {
  color: $neutral100;
  overflow-wrap: break-word;
}

.ItemSubTitle {
  color: $neutral100;
  overflow-wrap: break-word;
}

.TitleWrapper {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  height: auto;
  border-radius: 14px 14px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  @media screen and #{$mobile} {
    font-size: 0.8em;
  }

  @media screen and #{$tablet} {
    font-size: 0.8rem;
  }
}

.ButtonWrapper {
  padding: 20px;
}
