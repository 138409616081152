@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  width: 100%;
  max-width: 400px;
}

.InnerWrapper {
  width: 100%;
  padding: 10px 0;
}

.InfoContentWrapper {
  display: flex;
  margin: 0 20px;
  padding-bottom: 10px;
  font-size: 0.9rem;
  color: $brand;
  border-bottom: 1px solid $brand;
}

.InfoContent {
  margin: 0;
}

.Emphasis {
  font-weight: 600;
}