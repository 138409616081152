$brand: hsl(220, 100%, 19%);
$brand600: hsl(220, 100%, 14%);
$brand500: hsl(220, 100%, 19%);
$brand400: hsla(220, 100%, 19%, 0.46);
$brand300: hsla(220, 100%, 19%, 0.25);
$brand200: hsla(220, 100%, 19%, 0.15);
$brandLight: lighten($brand, 70%);

$brandAlt: hsl(14, 83%, 55%);
$brandAlt600: hsl(14, 83%, 50%);
$brandAlt500: hsl(14, 83%, 55%);
$brandAlt400: hsla(14, 83%, 55%, 0.46);
$brandAlt300: hsla(14, 83%, 55%, 0.25);
$brandAlt200: hsla(14, 83%, 55%, 0.15);

$neutral900: hsl(0, 0%, 0%);
$neutral800: hsl(0, 0%, 20%);
$neutral700: hsl(0, 0%, 30%);
$neutral600: hsl(0, 0%, 40%);
$neutral500: hsl(0, 0%, 50%);
$neutral400: hsl(0, 0%, 70%);
$neutral300: hsl(0, 0%, 80%);
$neutral200: hsl(0, 0%, 90%);
$neutral100: hsl(0, 0%, 100%);
$neutral100Transparency: hsla(0, 0%, 100%, 0.75);
$neutral200Transparency: hsla(0, 0%, 90%, 0.3);

$neutralOpacity900: hsla(0, 0%, 0%, 0.9);
$neutralOpacity800: hsla(0, 0%, 0%, 0.8);
$neutralOpacity700: hsla(0, 0%, 0%, 0.7);
$neutralOpacity600: hsla(0, 0%, 0%, 0.6);
$neutralOpacity500: hsla(0, 0%, 0%, 0.5);
$neutralOpacity400: hsla(0, 0%, 0%, 0.4);
$neutralOpacity300: hsla(0, 0%, 0%, 0.3);
$neutralOpacity200: hsla(0, 0%, 0%, 0.2);
$neutralOpacity100: hsla(0, 0%, 0%, 0.1);

$danger: hsl(1, 80%, 61%);
$danger600: hsl(1, 80%, 56%);
$danger500: hsl(1, 80%, 61%);
$danger400: hsla(1, 80%, 61%, 0.50);
$danger300: hsla(1, 80%, 61%, 0.25);
$danger200: hsla(1, 80%, 61%, 0.15);

$warning: hsl(32, 87%, 55%);
$warning600: hsl(32, 87%, 50%);
$warning500: hsl(32, 87%, 55%);
$warning400: hsla(32, 87%, 55%, 0.50);
$warning300: hsla(32, 87%, 55%, 0.25);
$warning200: hsla(32, 87%, 55%, 0.15);

$success: hsl(165, 83%, 47%);
$success600: hsl(165, 83%, 42%);
$success500: hsl(165, 83%, 47%);
$success400: hsla(165, 83%, 47%, 0.50);
$success300: hsla(165, 83%, 47%, 0.25);
$success200: hsla(165, 83%, 47%, 0.15);

$info: hsl(240, 81%, 63%);
$info600: hsl(240, 81%, 58%);
$info500: hsl(240, 81%, 63%);
$info400: hsla(240, 81%, 63%, 0.50);
$info300: hsla(240, 81%, 63%, 0.25);
$info200: hsla(240, 81%, 63%, 0.15);

$materialTitleBackground: hsl(220, 19.5%, 84.9%);
$materialBackground: #F2F1F1;
$simulatorBackground: #FAF9F9;

$mobile: '(max-width: 768px)';
$tablet: '(min-width: 769px) and (max-width: 1300px)';
$desktop: '(min-width: 1301px)';
$white: #FFF;
