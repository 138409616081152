@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  min-height: 10rem;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
}

.recaptcha {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.InnerWrapper {
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 5px 15px $neutral200;
}