@import '../../../assets/StyleVariables.module.scss';

.DefaultTheme {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $brand;
  border-color: transparent;
  border-width: 0;
  color: $neutral100;
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: none;
  padding: 5px 8px 5px 15px;

  @media screen and #{$mobile} {
    font-size: 0.7em;
  }

  @media screen and #{$tablet} {
    font-size: 0.7rem;
  }

  &:hover {
    background: $brand600;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $brand400;
  }
}

.IconWrapper {
  margin-left: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Light {
  background: transparent;
  color: $neutral700;

  &:hover {
    background: $neutral200;
  }

  &:disabled {
    background-color: $neutral400;
  }
}

.Full {
  width: 100%;
  padding: 10px;
}

.LightGrey {
  background-color: $neutral200;
  color: $neutral700;

  &:hover {
    background: $neutral300;
    color: $neutral700;
  }

  &:disabled {
    background-color: $neutral400;
  }
}

.Orange {
  background-color: $brandAlt;
  color: $neutral100;

  &:hover {
    background: $brandAlt600;
    color: $neutral100;
  }

  &:disabled {
    background-color: $neutral400;
  }
}
