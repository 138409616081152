@import '../../../assets/StyleVariables.module.scss';

.Wrapper {
  width: 80%;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: $materialTitleBackground;
  margin: 0 10%;
  align-items: center;
  cursor: pointer;
}

.WrapperMenu {
  border-radius: 10px;
  margin: 20px 10%;
}

.LogoTitle {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 15px;

  &:focus {
    outline: none;
  }
}

.FavoriteItemWrapper > div{
  border-radius: 10px;
  margin: 20px 10%;
}

.BorderRadiusBottom {
  border-radius: 0 0 10px 10px;
}

.LogoWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 10px 0 5px;
}

.Sticker {
  position: absolute;
  height: 30px;
  margin-left: -16px;
  margin-top: -0.6em;
  width: 25px;
}

.Logo {
  width: 30px;
  height: 30px;
}

.Icon {
  padding-right: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.Title {
  display: flex;
  align-items: center;
  font-weight: bold;

  p {
    margin: 0;
    color: $neutralOpacity800;
  }
}

@media screen and #{$desktop} {
  .WrapperExpanded {
    width: 90%;
    border-radius: 0;
    padding-right: 10%;
  }

  .WrapperExpandedLast {
    border-radius: 0 0 0 10px;
  }
}
