@import '../../../assets/StyleVariables.module.scss';

.Wrapper {
  width: 32%;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  font-size: 12px;
  color: $neutral700;
  margin-bottom: 10px;
  font-weight: 600;
}

.Elements {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.ElementWrapper {
  margin-right: 5px;
  border-radius: 5px;
  font-weight: bold;
  padding: 2px 5px;
}

.Element {
  text-transform: capitalize;
  color: $neutral600;
  width: 100%;
  font-size: 12px;
  cursor: pointer;
}

.SelectedWrapper {
  background-color: $brand;
}

.SelectedElement {
  color: $neutral100;
}
