@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  background-color: $neutral200;
  color: $neutral700;
  min-height: 40px;
  margin: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px;
}

.Title {
  display: flex;
  align-items: center;

  p {
    margin: 0 0 0 10px;
  }
}

.Value {
  color: $brand;
  min-width: max-content;
  margin-left: 10px;
}

.Chip {
  color: $brandAlt;
  width: 25px;
  min-width: 25px;
  height: 25px;
  background-color: $white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $brandAlt;
}

.NoChip {
  width: 25px;
  height: 25px;
}

@media screen and #{$mobile} {
  .Wrapper {
    margin: 10px 10px;
    padding: 0 10px;
    font-size: 13px;
  }
}
