@import '../../../assets/StyleVariables.module.scss';

.Wrapper {
  width: 100%;
  background-color: $neutral200Transparency;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
}

.Title {
  font-size: 14px;
  color: $neutral700;
  margin-bottom: 10px;
  font-weight: 600;
}

.Elements {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ElementWrapper {
  margin-right: 5px;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: bold;
}

.Element {
  text-transform: capitalize;
  color: $neutral600;
  width: 100%;
  font-size: 12px;
  cursor: pointer;
}

.SelectedWrapper {
  background-color: $brand;
}

.SelectedElement {
  color: $neutral100;
}