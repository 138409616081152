@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.InformativeBackground {
  flex: auto;
  justify-content: center;
}

.Logo {
  width: 150px;
  height: auto;
  margin-top: 20%;
}

.Separator {
  background-color: $neutral200;
  width: 50%;
  height: 1px;
  margin: 15px 0;
}

.InformativeImage {
  border-radius: 5px;
  width: 400px;
  aspect-ratio: 19/11;
}