@import '../../../assets/StyleVariables.module.scss';

.Wrapper {
  width: 100%;
  height: 40px;
  background-color: $neutral100;
  color: #000;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  align-content: center;
  text-align: center;
  padding: 0;
}

.WrapperActive {
  background-color: $brand;

  div {
    color: $neutral100;
  }
}

.ContentWrapper {
  flex-direction: row;
  align-items: center;
  display: flex;
  border: none;
  background-color: inherit;

  &:focus {
    outline: none;
  }
}

@media screen and #{$mobile} {
  .Wrapper {
    justify-content: left;
    padding-left: 8%;
  }
}

