@import '../../assets/StyleVariables.module.scss';

.Wrapper {
  margin: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: [materialsGroups] 25% [equipment] auto;
  grid-template-rows: [header] 90px [content] auto;
  grid-template-areas:
      "header header"
      "materialsGroups equipment";
}

.Header {
  grid-area: header;
}

.CookieGroup {
  background-color: $brand;
  height: 100%;
}

.WrapperCookiesGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin: 1% 0;
  grid-area: materialsGroups;
}

.HideOffersGroup {
  display: none;
}

.Equipment {
  grid-area: equipment;
  margin: 2%;
}

@media screen and #{$mobile} {
  .Wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: [header] 60px [materialsGroups] auto;
    grid-template-areas:
            "header"
            "materialsGroups"
            "equipment";
    height: auto;
  }
}

@media screen and #{$tablet} {
  .Wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: [header] 60px [materialsGroups] auto;
    grid-template-areas:
            'header'
            'materialsGroups'
            'equipment';
    height: auto;
  }
}

.WhiteText {
  color: white;
  margin-top: 20px;
}

.RoundAccountUserIcon {
  height: 32px;
  width: 32px;
  margin-right: 15px;
  margin-top: 15px;
}

.CheveronIcon {
  height: 26px;
  width: 26px;
  margin-right: 5px;
  margin-top: 15px;
}

.HeaderMenuCookie {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2% 2% 20px 15px;
}

.ParagraphUser {
  margin-left: 20px;
  margin-right: 20px;
  color: $materialTitleBackground;
  font-size: 12px;
}

.WhiteLigne {
  border-top: 2px solid $materialTitleBackground;
  margin: 0 10px;
}

.FlexRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 15px 5%;
}

.LogoutImage {
  height: 32px;
  width: 32px;
  margin-right: 15px;
}

.LogoutButton {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 8px 10px 1px;
  margin-left: auto;
}

.HomeButton {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 4px 10px;
  margin-right: auto;
  margin-top: 10px;
}

.ReturnButton {
  color: #fe5815;
}