@import '../../assets/StyleVariables.module.scss';

.Wrapper {
  height: 100vh;
  width: 100vw;
  display: grid;
  background-color: $neutral100;
}

.Footer {
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: 99;
  padding: 0 20px;
  color: $neutral600;
  bottom: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.BuildVersion {
  margin: 0;
  color: $neutral800;
  padding: 3px;
  border-radius: 5px;
  background-color: $neutral100Transparency;
}

.Ecofinancement {
  img {
    border-radius: 20px;
  }
}

.Confidentialite {
  color: $neutral800;
  padding: 3px;
  border-radius: 5px;
  background-color: $neutral100Transparency;
  cursor: pointer;
}

.NoSpace{
  margin-right: auto;
  margin-left: 5px;
}
.UpdateDate {
  margin-top: 10px;
  text-align: center;
}

.LoginBackground {
  background-size: cover;
}

.LoginContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and #{$mobile} {
  .Wrapper {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr 20px;
    grid-template-areas:
      'content'
      'footer';
  }

  .LoginBackground {
    display: none;
  }

  .Footer {
    grid-area: footer;
    position: initial;

    div {
      margin: 5px 5px 0 5px;
    }
  }

  .Confidentialite {
    color: $brand;
  }

  .LoginContent {
    grid-area: content;
    padding: 0 20px;
  }
}

@media screen and #{$tablet} {
  .Wrapper {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'content'
        'background';
  }

  .LoginBackground {
    grid-area: background;
    background-position: 50% 50%;
  }

  .LoginContent {
    grid-area: content;
  }
}

@media screen and #{$desktop} {
  .Wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100vh;
    grid-template-areas: 'background content';
  }

  .LoginBackground {
    grid-area: background;
    background-position: bottom;
  }

  .LoginContent {
    grid-area: content;
  }
}
