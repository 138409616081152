@import '../../../assets/StyleVariables.module.scss';

.WrapperMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $neutral100;
  margin-bottom: 0;
  min-width: 350px;
  min-height: 40px;
}

.SectionTitle {
  font-size: 16px;
  font-weight: bold;
  color: $neutral900;
  margin-bottom: 0;
}

.IconWrapper {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: $neutral700;

  img {
    width: 20px;
    height: 20px;
  }
}

@media screen and #{$mobile} {
  .WrapperMenu {
    flex-direction: column;
    min-width: inherit;
  }
}
