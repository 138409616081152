@import '../../../../assets/StyleVariables.module';

.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Label {
  font-weight: 600;
  color: $neutral700;
  margin-bottom: 4px;
  font-size: 14px;
}

.InputWrapper {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  border: $neutral400 1px solid;
  background-color: $neutral200Transparency;
}

.Input {
  flex: 1;
  color: $neutral700;
  background-color: inherit;
  padding-right: 10px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 24px;
  max-width: calc(100% - 37px);
}

.Input:invalid {
    box-shadow: none;
}

.InputSignWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  background-color: inherit;
}

.InputSign {
  color: $brand;
  font-weight: bold;
  font-size: 30px;
}

.Error {
  color: red;
  font-weight: bold;
}