@import '../../../assets/StyleVariables.module';

.Wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-self: stretch;
  padding-left: 5px;
}

