@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,900&display=swap');

@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: hsl(240, 81%, 63%);
$pink: #e83e8c;
$red: hsl(1, 80%, 61%);
$orange: hsl(32, 87%, 55%);
$yellow: #ffc107;
$green: hsl(165, 83%, 47%);
$teal: #20c997;
$cyan:#17a2b8;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $purple;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// Accordion text color contrast threshold white to black.
$yiq-contrasted-threshold:  170;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$btn-font-weight: $font-weight-bold;

$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.15;
$font-size-sm: $font-size-base * .875;

$brand: hsl(220, 100%, 19%);

@import "~bootstrap/scss/bootstrap";

label.custom-label {
  margin: 0;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  color: $gray-700;
}

html, body, #root {
  height: 100%;
  font-family: 'Open sans', 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
  opacity:0;
}

.slick-slider,
.slick-track,
.slick-slide > div,
.slick-slide > div > div {
  height: 100%;
}

.slick-slider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-list {
  height: calc(100% - 40px);
  width: 100%;
}

.slick-dots {
  bottom: 0;
}

.slick-slide {
  padding: 0 5px;
}

.slick-slide div:focus{
  outline: none;
}

.slick-prev:before {
  content: '\2039'
}
.slick-next:before {
  content: '\203A'
}

.slick-prev:before, .slick-next:before {
  font-size: 60px;
  color: $brand;
}

.slick-prev, .slick-next {
  top: 40%;
}

.svg-inline--fa .fa-secondary {
  opacity: 1;
}

.blue-domo {
  color: #001A70;
}
.orange-domo {
  color: #fe5815;
}
.green-domo {
  color: #62a855;
}
.font-size-714em {
  font-size: 0.714em;
}
.font-size-857em {
  font-size: 0.857em;
}
.font-size-1em {
  font-size: 1em;
}
.font-size-1143em {
  font-size: 1.143em;
}
.font-size-1286em {
  font-size: 1.286em;
}
.font-size-1429em {
  font-size: 1.429em;
}
.font-size-1571em {
  font-size: 1.571em;
}
.font-size-1741em {
  font-size: 1.714em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.striped tr:nth-child(odd) td {
  background-color: rgba(0, 0, 0, 0.05);;
}

.striped tr:nth-child(even) td {
  background-color: #ffffff;
}
