@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  background-color: $brand;
  color: $neutral100;
  min-height: 60px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;

  h5 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 15px;
  }
}

@media screen and #{$mobile} {
  .Wrapper {
    padding-left: 10px;
    margin-bottom: 5px;

    h5 {
      font-size: 14px;
    }

    p {
      font-size: 13px;
    }
  }
}
