@import '../../../../assets/StyleVariables.module.scss';

.Wrapper {
  background-color: $neutral100;
  width: 100%;
  height: 100%;
}

.Header {
  height: 80px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.WrapperHeader {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: $brand;
  padding: 0 30px;
}

.Title {
  color: $neutral100;
  display: flex;
  align-items: center;
  font-weight: bold;

  p {
    margin: 0;
    padding-left: 10px;
  }
}

.Description {
  padding: 30px;
  height: calc(100% - 80px);

  p {
    text-align: justify;
    color: $neutralOpacity700;
  }

  a {
    font-weight: bold;
    color: $neutral100;
    border-radius: 10px;
    padding: 15px 20px;
    margin: auto;
    width: 66%;
    display: block;
    text-align: center;
    background-color: $brandAlt;
  }

  h3 {
    font-size: 18px;
    color: $brand;
    margin-bottom: 10px;
  }

  ul {
    margin-left: -1em;
  }

  li {
    list-style: none;

    div {
      display: inline;
    }
  }

  li:before {
    content: "";
    width: 0;
    height: 0;
    border: 5px solid $brand;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  div {
    height: 100%;
  }

  iframe {
    height: 100%;
  }
}

@media screen and #{$mobile} {
  .Wrapper {
    margin-top: 0;
  }

  .WrapperHeader{
    padding-left: 40px;
  }
}

@media screen and #{$tablet} {
  .Wrapper {
    margin-top: 0;
  }

  .WrapperHeader{
    padding-left: 40px;
  }
}

