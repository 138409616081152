@import '../../../assets/StyleVariables.module.scss';

.ModalHeader {
  display: flex;
  justify-content: center;
  padding: 1rem 1rem;
  color: $brandAlt;
  text-align: center;

  div {
    font-weight: bold;
  }
}

.ModalFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;

  button {
    width: 95%;
    display: block;
    margin: 5px 0;
    padding: 10px;
    border-radius: 10px;
  }
}

.ModalBody {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  color: $brand;
}

.ModalOverview {
  max-height: 60vh;
  overflow: auto;
}

.CrossButton {
  background: transparent;
  border: none;
  border-radius: 5px;
  padding: 4px 4px;
}

.CrossIcon {
  background-color: white;
  color: #4d4d4d;
}

.RightCross {
  margin-left: auto;
  margin-bottom: 3%;
}