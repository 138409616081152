@import '../../assets/StyleVariables.module.scss';

.Wrapper {
  margin: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: [materialsGroups] 25% [equipment] auto;
  grid-template-rows: [header] 90px [content] auto;
  grid-template-areas:
      "header header"
      "materialsGroups equipment";
}

.Header {
  grid-area: header;
}

.MaterialGroup {
  background-color: $brand;
  height: 100%;
}

.WrapperMaterialsGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  grid-area: materialsGroups;
}

.HideOffersGroup {
  display: none;
}

.Equipment {
  grid-area: equipment;
}

@media screen and #{$mobile} {
  .Wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: [header] 60px [materialsGroups] auto;
    grid-template-areas:
            "header"
            "materialsGroups"
            "equipment";
    height: auto;
  }
}

@media screen and #{$tablet} {
  .Wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: [header] 60px [materialsGroups] auto;
    grid-template-areas:
            'header'
            'materialsGroups'
            'equipment';
    height: auto;
  }
}

.NoMaterialsGroup {
  color: $neutral100;
  padding: 1em;
  margin-top: 1em;
}
