@import '../../assets/StyleVariables.module.scss';

.Wrapper {
  margin: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: [informationsGroup] 25% [information] auto;
  grid-template-rows: [header] 90px [content] auto;
  grid-template-areas:
      "header header"
      "informationsGroup information";
}

.Header {
  grid-area: header;
}

.InformationGroup {
  background-color: $brand;
  height: 100%;
}

.WrapperInformationsGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  grid-area: informationsGroup;
}

.HideInformationsGroup {
  display: none;
}

.Information {
  grid-area: information;
  margin-top: 40px;
}

@media screen and #{$mobile} {
  .Wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: [header] 60px [informationsGroup] auto;
    grid-template-areas:
            "header"
            "informationsGroup"
            "information";
    height: auto;
  }

  .Information {
    height: calc(100vh - 260px);
    margin-top: 0;
  }
}

@media screen and #{$tablet} {
  .Wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: [header] 60px [informationsGroup] auto;
    grid-template-areas:
            'header'
            'informationsGroup'
            'information';
    height: auto;
  }

  .Information {
    height: calc(100vh - 260px);
    margin-top: 0;
  }
}
