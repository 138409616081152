@import '../../assets/StyleVariables.module.scss';

.Wrapper {
  align-items: center;
  background-color: $simulatorBackground;
  margin-top: 40px;
  height: calc(100% - 40px);
}

.Header {
  height: 50px;
  background-color: $brand;
  color: $neutral100;
  vertical-align: center;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: bold;
}

.FormWrapper {
  display: flex;
  flex-direction: row;
  margin: 30px 50px 0 50px;
}

.FormFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  margin: 10px;
}

.FormMonthly {
  margin-left: 40px;
  flex: 1.5;
}

.Tab {
  width: 100%;
}

.MonthlyKey {
  text-align: center;
}

.MonthlyValueBold {
  text-align: center;
  padding: 10px;
  color: $info600;
  font-weight: bold;
  font-size: 15px;
}

.MonthlyValue {
  text-align: center;
  padding: 10px;
  color: $info600;
  font-size: 13px;
}

.MonthlyValueTitle {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}

.MonthlyValueTitleBold {
  text-align: center;
}

.MonthlyArrow {
  width: 15px;
  height: 17px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  background: url('../../assets/images/arrow-right.svg');
  cursor: pointer;
}

.AmountLimit {
  color: $brand;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 10px;
  align-self: flex-start;
}

.AmountLimitError {
  color: $danger;
}

.Info {
  font-size: 13px;
  text-align: end;
  width: 100%;
  color: $neutral500;
}

@media screen and #{$tablet}, #{$mobile} {
  .FormWrapper {
    flex-direction: column;
  }

  .FormFields {
    width: auto;
  }

  .FormMonthly {
    margin: 10px;
    width: auto;
  }
}

@media screen and #{$mobile} {
  .FormWrapper {
    margin: 10px 25px 0 25px;
  }
}

.Error {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: red;
}

.wrapperInput {
  display: flex;
  width: 100%;
  background-color: $neutral200Transparency;
  border-radius: 6px;
}
