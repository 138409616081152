@import '../../../assets/StyleVariables.module.scss';

.Snackbar {
  position: fixed;
  z-index: 9999;
  width: 60%;
  bottom: 40px;
  left: calc(50% - 30%);
  text-align: center;
  display: flex;
  justify-content: space-between;
}

@media screen and #{$mobile} {
  .Snackbar {
    width: 80%;
    left: calc(50% - 40%);
  }
}
