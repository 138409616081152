@import '../../assets/StyleVariables.module.scss';

.Wrapper {
  align-items: center;
  background-color: $simulatorBackground;
  margin-top: 40px;
  height: calc(100% - 40px);
  padding: 40px;
}

.InfosUser {
  margin: 10px 20px;
  font-size: 14px;
}

.ButtonAction {
  display: flex;
  margin: 30px 20px 10px 20px;
  justify-content: space-between;

  button {
    font-size: 15px;
    width: 48%;
  }
}

@media screen and #{$tablet}, #{$mobile} {
  .Wrapper {
    padding: 0;
  }
}

@media screen and #{$tablet} {
  .FormFields {
    padding: 0 10px;
  }
}

@media screen and #{$mobile} {
  .ButtonAction {
    flex-direction: column;

    button {
      margin: 10px 0;
      width: 100%;
    }
  }

  .ShowFipenButton {
    margin: 0;
  }

  .FormFields {
    padding: 0;
  }
}