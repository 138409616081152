@import '../../assets/StyleVariables.module.scss';

.Wrapper {
  align-items: center;
  background-color: $simulatorBackground;
  margin-top: 40px;
  height: calc(100% - 40px);
}

.Header {
  height: 50px;
  background-color: $brand;
  color: $neutral100;
  vertical-align: center;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: bold;
}

.Results {
  margin: 15px 40px 10px 40px;
}

.Insurances {
  display: flex;
  margin-bottom: -5px;
}

.InsuranceBorrower {
  flex: 1;
}

.InsuranceCoborrower {
  flex: 1;
}

.ButtonAction {
  display: flex;
  margin-top: 10px;
  text-align: center;

  button {
    flex: 1;
    padding: 10px;
  }
}

.Button {
  margin: 10px 0;
}

.OfflineMessage {
  margin-top: 10px;
  color: $danger;
}

.Info {
  font-size: 13px;
  text-align: end;
  width: 100%;
  color: $neutral500;
}

@media screen and #{$mobile} {
  .Results {
    margin: 10px 0;
  }

  .Insurances {
    flex-direction: column;
  }

  .Button {
    margin: 0 20px;
  }
}

@media screen and #{$tablet} {
  .Results {
    margin: 10px 0;
  }

  .Button {
    margin: 0 10px;
  }
}
