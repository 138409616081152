@import '../../../assets/StyleVariables.module.scss';

.Wrapper {
  width: 80%;
  height: 60px;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: $neutral100;
  margin: 20px 10%;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.WrapperExpanded {
  width: 80%;
  margin-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.IconWrapper {
  border-radius: 15px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: $neutral300;
  margin: 0 10px;
}

.IconWrapperExpanded {
  background-color: $brand;
  color: white;
}

.Title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $neutralOpacity700;

  p {
    margin: 0;
  }
}
