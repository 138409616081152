@import '../../../assets/StyleVariables.module.scss';

.HeaderStyled {
  width: 100%;
  height: 90px;
  padding: 10px 40px;
  color: $danger;
  display: flex;
  align-items: center;
  grid-area: header;
  justify-content: space-between;
}

.HeaderLogout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Home {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 4px 10px;
  margin-right: auto;
}
.HomeMargin {
  background-color: $materialTitleBackground;
  border: none;
  border-radius: 5px;
  padding: 4px 10px;
  margin-right: 200px;
}

.MenuCookieButton{
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 4px 10px;
}

.HomeIcon {
  margin-right: 5px;
}

.ReturnButton {
  color: #fe5815;
}

.ImgLogo {
  height: 60px;
  width: auto;
  float: right;
  border-radius: 5px;
  cursor: pointer;
}

.Decoy {
  width: 100px;
}

.MenuCookieImage {
  height: 32px;
  width: 32px;
  margin-right: 15px;
}

@media screen and #{$mobile} {
  .ImgLogo {
    height: 40px;
  }
}

@media screen and #{$tablet} {
  .ImgLogo {
    height: 40px;
  }
}
