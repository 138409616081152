@import '../../assets/StyleVariables.module.scss';

.Wrapper {
  width: 100%;
  height: calc(100% - 120px);
  max-width: 2000px;
  margin: auto;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  padding: 0 40px;
}

.HideWrapper {
  display: none;
}

.SectionTitle {
  font-size: 1.3em;
  font-weight: 700;
  color: $neutral100;
  margin-bottom: 0;
  margin-left: 1.2em;
}

.IconWrapper {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $neutral100;
}

.LinksWrapper {
  width: 380px;
  flex-shrink: 0;
}

.CarouselWrapper {
  aspect-ratio: 2/1;
  height: 410px;
  flex-shrink: 1;
  margin: auto;
  padding: 0 40px;
}

.ButtonIcon {
  fill: $neutral100;
  height: 40px;
  width: 40px;
}

.Information {
  height: calc(100% - 100px);
  margin-top: 40px;
}

.NoNews {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.NoNewsImg {
  height: 80%;
  margin-bottom: 10px;
}

@media screen and (max-width: 1300px) and (min-width: 430px) {
 .Wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 0;
  }

  .LinksWrapper {
    height: calc(100% - 260px);
    min-height: 150px;
    max-height: 350px;
    width: 100%;
    padding: 0 20px;
  }

  .CarouselWrapper {
    width: 100%;
    height: 260px;
    min-height: 260px;
    padding: 0 40px;
    margin: 20px auto;
    overflow: hidden;
  }
}

@media screen and (max-width: 430px) {
  .Wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 0;
  }

  .LinksWrapper {
    height: calc(100% - 60vw);
    min-height: 150px;
    max-height: 350px;
    width: 100%;
    padding: 0 20px;
  }

  .CarouselWrapper {
    width: 100%;
    height: 45vw;
    min-height: 60vw;
    padding: 0 10px;
    margin: 20px 10px;
  }
}

@media screen and #{$tablet} {
  .LinksWrapper {
    display: flex;
    flex-direction: row;
  }

  .SectionTitle {
    margin: 5px 0;
  }
}

@media screen and #{$mobile} {
  .LinksWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
