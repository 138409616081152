@import '../../../../assets/StyleVariables.module';

.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Label {
  font-weight: 600;
  color: $neutral700;
  margin-bottom: 4px;
  font-size: 11.5px;
}

.InputWrapper {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  border: $neutral400 1px solid;
}

.Input {
  flex: 1;
  color: $neutral700;
  background-color: inherit;
  border: none;
  outline: none;
  text-align: center;
  font-size: 12px;
}

.Input:invalid {
  box-shadow: none;
}

.InputSignWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: inherit;
  color: $brand;
  font-weight: bold;
}

.InputSign {
  color: $brand;
  font-weight: bold;
  font-size: 30px;
}

.Error {
  font-size: 12px;
  font-weight: bold;
  color: red;
}
