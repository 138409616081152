@import '../../../assets/StyleVariables.module';

.Wrapper {
  display: flex;
  flex-direction: column;
  background-color: $neutral200Transparency;
  border-radius: 10px;
  margin: 10px 20px;
  padding: 10px;
  font-size: 14px;
}

.Label {
  font-weight: 600;
  color: $neutral700;
  margin-bottom: 4px;
}

.InputWrapper {
  width: 100%;
}

.Input {
  background-color: inherit;
  padding-right: 10px;
  border: none;
  outline: none;
  color: $info600;
  font-weight: bold;
  width: 100%;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $neutral500;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $neutral500;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $neutral500;
  }
}

.Error {
  color: red;
  font-weight: bold;
}
